


























































import {
  computed, ref, useRoute, defineComponent,
} from '@nuxtjs/composition-api';
import SfHeading from '/storefrontUI/components/components/atoms/SfHeading/SfHeading.vue';
import SfButton from '/storefrontUI/components/components/atoms/SfButton/SfButton.vue';
import SfCallToAction from '/storefrontUI/components/components/molecules/SfCallToAction/SfCallToAction.vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfHeading,
    SfButton,
    SfCallToAction,
  },
  setup(props, context) {
    context.emit('changeStep', 4);
    const route = useRoute();

    const companyDetails = ref({
      name: 'Need',
      email: 'service@needstore.it',
      tel: 'whatsapp: 3713862596',
    });
    const orderNumber = computed(() => route.value.query.order ?? '');

    return {
      addBasePath,
      companyDetails,
      orderNumber,
    };
  },
});
